import React, { Component } from 'react'

import { GET_RADIO } from '../api'
import { executeGet } from '../functions/Kubuku'
import SkeletonRadio from '../components/SkeletonRadio'

class Radio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: true,
      flagData: false,
      color: 'bg-orange',
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash: ''
    }
    console.log(this.props)
  }

  componentDidMount = async () => {
    this.getTheme();
    this.getRadio()
  }

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  gotoRadioDetail = async (radio) => {
    window.location.href = '/detailRadio/' + radio.id
  }

  getRadio = async () => {
    try {
      this.setState({
        isLoading: true
      })
      const json = await executeGet(GET_RADIO)
      if (json.code == 404) {
        window.location.href = 'https://titikbaca.id'
      } else {
        this.setState({
          data: json,
          isLoading: false,
          flagData: true
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  render() {
    return (
      <div>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div className={"flex p-4 " + this.state.color + " text-lg text-white font-semibold items-center"}>Radio</div>
        </nav>
        {this.state.isLoading && <SkeletonRadio />}
        {this.state.flagData && (
          <>
            <div className="p-4 mt-16">
              <div className="grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-4">
                {this.state.data.length > 0 && this.state.flagData && (
                  <>
                    {this.state.data.map((item) => {
                      return (
                        <div key={item.id} onClick={() => this.gotoRadioDetail(item)} id={item.id} className=" cursor-pointer rounded-xl shadow-md hover:shadow-lg duration-150 ease-out">
                          <div className="h-[165px] overflow-hidden">
                            <img src={item.imageRadio} alt="logo radio" className="rounded-xl w-full mx-auto" />
                          </div>
                          <div className="p-2">
                            <div className="title-book text-sm text-black font-semibold line-clamp-1">{item.namaChannel}</div>
                            <div className="author mt-1 text-xs text-gray-light line-clamp-2">{item.tagline}</div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Radio
