import React, { Component } from 'react'
import { TITIK_BACA_YOUTUBE } from '../api'
import { executeGet, executePost, formatTanggal } from '../functions/Kubuku'
import SkeletonYoutube from '../components/SkeletonYoutube'
import YouTube, { YouTubeProps } from 'react-youtube'
import { useParams, useNavigate } from 'react-router-dom'

import user_video from '../assets/images/youtube-logo.png'

class YoutubeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      id: this.props.params.id,
      dataBerita: '',
      alias: 'kfh-12f9-kas',
      lokasi: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      flagData: false,
      youtube: [],
      hal: 1,
      noGPS: false
    }
  }

  componentDidMount = async () => {
    this.setState({
      nama: window.localStorage.getItem('nama'),
      alias: window.localStorage.getItem('alias')
    })
    this.getLocation()
  }

  getLocation = () => {
    if (navigator.geolocation) {
      var location_timeout = setTimeout('this.geolocFail()', 30000)

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(location_timeout)
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let alt = position.coords.altitude;

          this.getYoutube(lat, long, alt)
        },
        (error) => {
          clearTimeout(location_timeout)
          this.geolocFail()
        }
      )
    } else {
      this.geolocFail()
    }
  }

  geolocFail = () => {
    this.setState({
      noGPS: true
    })
    this.getYoutube(this.state.lat, this.state.long)
  }

  getYoutube = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      let hal = parseInt(this.state.hal)
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', hal)
      const json = await executePost(TITIK_BACA_YOUTUBE + this.state.alias, formData)
      if (hal == 1) {
        this.setState({
          flagData: false,
          hal: hal + 1,
          isLoading: true
        })

        let id = this.state.id
        this.playYoutube(id, json.data)
      } else {
        console.log("HALLLL " + hal);
        let oldjson = this.state.youtube
        this.setState({
          youtube: oldjson.concat(json.data),
          flagData: true,
          hal: hal + 1,
          isLoading: false
        })
        
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  playYoutube = async (id, data) => {
    console.log('ID : ' + id)
    this.setState({
      flagData: false,
      isLoading: true
    })
    if (id != '0') {
      console.log('reordering array')
      let dt = data //this.state.youtube;

      let arrVideo = []
      let arrNormal = []
      let arrFinal = []
      dt.map((item) => {
        if (item.id == id) {
          arrVideo.push(item)
        } else {
          arrNormal.push(item)
        }
      })
      arrVideo.map((item) => {
        arrFinal.push(item)
      })
      arrNormal.map((item) => {
        arrFinal.push(item)
      })
      this.setState({
        youtube: arrFinal,
        id: '0',
        flagData: true,
        isLoading: false
      })
      console.log(arrFinal)
      console.log('--SET 0')
    } else {
      this.setState({
        youtube: data,
        id: '0',
        flagData: true,
        isLoading: false
      })
    }
  }

  render() {
    const opts = {
      width: '100%',
      playerVars: {
        autoplay: 1
      }
    }
    const optsLg = {
      height: '420px',
      width: '100%',
      playerVars: {
        autoplay: 1
      }
    }

    return (
      <div>
        {this.state.isLoading && <SkeletonYoutube />}
        {this.state.flagData && (
          <>
            <nav className="p-4 fixed flex w-full items-center top-0 z-10 bg-orange shadow-md">
              <div className="text-lg text-white font-semibold items-center">Video</div>
            </nav>
            <div className="pt-[60px] mb-16 sm:block md:block">
              <div>
                {console.log("HEREEE")}
                {console.log(this.state.youtube)}
                {this.state.youtube.length > 0 && this.state.flagData && (
                  <>
                    {this.state.youtube.slice(0, 1).map((item) => {
                      return (
                        <div className="mb-2.5 bg-white shadow-md hover:shadow-lg">
                          <div className="overflow-hidden">
                            <YouTube videoId={item.url_video.replace('https://youtu.be/', '').replace('https://www.youtube.com/watch?v=', '')} opts={opts} />
                          </div>
                          <div className="flex min-h-[96px] p-2.5 items-center">
                            <div className="mr-3">
                              <div className="w-11 h-11 overflow-hidden rounded-full border-2 border-slate-300">
                                <img src={user_video} alt="logo channel" />
                              </div>
                            </div>
                            <div className="w-11/12">
                              <h3 className="mb-1 font-semibold text-sm text-black line-clamp-2">{item.judul}</h3>
                              <div className="leading-[10px] overflow-hidden">
                                <div className="inline text-xs text-gray-light mr-0.5">{item.channel}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
                <div className='md:hidden sm:block'>
                <div className="columns-1 gap-2">
                  {this.state.youtube.length > 0 && this.state.flagData && (
                    <>
                      {this.state.youtube.slice(1, 500).map((item) => {
                        return (
                          <div className="px-4">
                            <div href="#" onClick={() => this.playYoutube(item.id, this.state.youtube)} className="flex mb-2.5 p-2.5 border bg-white rounded-2xl shadow-md">
                              <div style={{ background: "url('" + item.thumbnail + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                              <div className="w-8/12">
                                <h3 className="mt-1 text-sm text-black font-semibold line-clamp-3">{item.judul}</h3>
                                <div className="mt-2 text-xs text-gray-light">{item.channel}</div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
                </div>
                <div className='hidden md:block'>
                <div className="mb-4 px-4 grid grid-cols-4 gap-4">
                  {this.state.youtube.length > 0 && this.state.flagData && (
                    <>
                      {this.state.youtube.slice(1, 5).map((item) => {
                        return (
                          <div onClick={() => this.playYoutube(item.id, this.state.youtube)} className="rounded-xl border shadow-md">
                            <div className="h-[120px] overflow-hidden">
                              <img src={item.thumbnail} alt="cover buku" className="rounded-xl" />
                            </div>
                            <div className="p-2">
                              <h3 className="text-sm text-black font-semibold line-clamp-3">{item.judul}</h3>
                              <div className="mt-4 mb-2 text-xs text-gray-light">{item.channel}</div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
                </div>
                <div className="text-center pb-8">
                  <button type="button" onClick={() => this.getYoutube(this.state.lat, this.state.long)} className="mt-2 py-2 px-8 border-[1px] border-orange text-orange text-sm font-semibold text-center rounded-full">
                    Lihat Lebih Banyak
                  </button>
                </div>
              </div>
            </div>

            
          </>
        )}
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate()
  return <YoutubeComponent {...props} navigate={navigate} params={useParams()} />
}
export default WithNavigate
