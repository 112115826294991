import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Splash from './Splash'
import Home from './Home'
import News from './News'
import GPSLocation from './GPSLocation'
import Radio from './Radio'
import DetailBooks from './DetailBook'
import DetailRadio from './RadioDetail'
import DetailNews from './NewsDetail'
import QRScanner from './QRScanner'
import KategoriBuku from './KategoriBuku'
import RssDetail from './RssDetail'
import ReadingBook from './ReadingBook'
import ListBuku from './ListBuku'
import ListYoutube from './Youtube'
import ListRSS from './ListRSS'
import BacaBerita from './NewsViewer'
import AudioPlayer from './AudioPlayer'
import LiterasiKeluarga from './LiterasiKeluarga'
import InputKTP from './InputKTP'

export default function index() {
  return (
    <Routes>
      <Route exact path="/" element={<Splash />} />
      <Route exact path="/splash" element={<Splash />} />
      <Route exact path="/:alias" element={<Splash />} />
      <Route exact path="/home/:alias" element={<Home />} />
      <Route exact path="/news" element={<News />} />
      <Route exact path="/audioplayer/:id" element={<AudioPlayer />} />
      <Route exact path="/audioplayerKeluarga/:id" element={<AudioPlayer />} />
      <Route exact path="/rss/:id" element={<RssDetail />} />
      <Route exact path="/sectionkeluarga/:token" element={<LiterasiKeluarga />} />
      <Route exact path="/location" element={<GPSLocation />} />
      <Route exact path="/radio" element={<Radio />} />
      <Route exact path="/inputktp/:tipe/:alias" element={<InputKTP />} />
      <Route exact path="/qrscanner" element={<QRScanner />} />
      <Route exact path="/listBuku/:tipe/:alias" element={<ListBuku />} />
      <Route exact path="/detail/:tipe/:id/:alias/:token" element={<DetailBooks />} />
      <Route exact path="/detailKeluarga/:tipe/:id/:alias/:token" element={<DetailBooks />} />
      <Route exact path="/bacaBuku/:id/:alias/:judul/:tipe/:token" element={<ReadingBook />} />
      <Route exact path="/bacaBukuKeluarga/:id/:alias/:judul/:tipe/:token" element={<ReadingBook />} />
      <Route exact path="/bacaBerita/:alias/:id/:tanggal" element={<BacaBerita />} />
      <Route exact path="/detailBerita/:id/:alias" element={<DetailNews />} />
      <Route exact path="/detailRadio/:id" element={<DetailRadio />} />
      <Route exact path="/youtube/:id" element={<ListYoutube />} />
      <Route exact path="/listrss/:id" element={<ListRSS />} />
      <Route exact path="/kategori/:kategori/:alias" element={<KategoriBuku />} />
    </Routes>
  )
}
