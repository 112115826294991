import React, { Component } from 'react'
import { TITIK_BACA, TITIK_BACA_GET_RSS } from '../api'
import { executeGet, executePost, formatTanggal } from '../functions/Kubuku'
import SkeletonNews from '../components/SkeletonNews'
import Glider from 'react-glider'
import 'glider-js/glider.min.css'

class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      dataBerita: '',
      alias: 'kfh-12f9-kas',
      lokasi: '',
      lat: '1', //'-7.557130246009252',
      long: '1',//'110.79448368501073',
      alt: '1',
      flagBerita: false,
      flagData: false,
      news: [],
      data: [],
      hal: 1,
      mode: 2,
      bgsemua: 'bg-orange',
      bglokal: 'bg-orange/10',
      bgnasional: 'bg-orange/10',
      txtsemua: 'text-white',
      txtlokal: 'text-orange',
      txtnasional: 'text-orange',
      noGPS: false,
      msgGPS: 'Tidak dapat mendeteksi lokasi anda, pastikan GPS anda aktif, dan mengijinkan browser anda membaca lokasi anda',
      color: 'bg-orange',
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash: ''
    }
  }

  componentDidMount = async () => {
    this.setState({
      nama: window.localStorage.getItem('nama'),
      alias: window.localStorage.getItem('alias')
    })
    this.getTheme();
    this.getLocation()
  }
  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }
  getLocation = () => {
    if (navigator.geolocation) {
      var location_timeout = setTimeout('this.geolocFail()', 30000)

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(location_timeout)
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let alt = position.coords.altitude;
          this.setState( {
            lat: lat,
            long: long,
            alt: alt
          })
          this.getBukuPopuler(lat, long, alt)
        },
        (error) => {
          clearTimeout(location_timeout)
          this.geolocFail()
        }
      )
    } else {
      this.geolocFail()
    }
  }

  geolocFail = () => {
    this.setState({
      noGPS: true
    })
    this.getRSS(1)
  }

  switchRss = async (mode) => {
    // console.log(mode)
    if (mode === 2) {
      this.setState({
        bgsemua: 'bg-orange',
        bglokal: 'bg-orange/10',
        bgnasional: 'bg-orange/10',
        txtsemua: 'text-white',
        txtlokal: 'text-orange',
        txtnasional: 'text-orange'
      })
    } else if (mode === 0) {
      this.setState({
        bgsemua: 'bg-orange/10',
        bglokal: 'bg-orange/10',
        bgnasional: 'bg-orange',
        txtsemua: 'text-orange',
        txtlokal: 'text-orange',
        txtnasional: 'text-white'
      })
    } else if (mode === 1) {
      // console.log("TENGAH")
      this.setState({ 
        bgsemua: 'bg-orange/10',
        bglokal: 'bg-orange',
        bgnasional: 'bg-orange/10',
        txtsemua: 'text-orange',
        txtlokal: 'text-white',
        txtnasional: 'text-orange' }
      , () => {
        // console.log('bglokal = ' + this.state.bglokal);
      }); 
      
    }
    this.getRSS(mode)
  }

  getRSS = async (mode) => {
    try {
      let bnews = this.state.news
      let alt = 1
      // console.log('MODE ' + this.state.mode)
      // console.log(mode)
      if (this.state.mode === mode) {
        console.log('semua')
        console.log("MODEEEEEEEEM: " + mode)
        
        //const json = await executeGet(TITIK_BACA_GET_RSS + '/' + mode + '/' + window.localStorage.getItem('alias') + '/' + this.state.lat + '/' + this.state.long + '/' + alt + '/' + this.state.hal)
        let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSRandom/1/" + mode + "/" + window.localStorage.getItem('alias') + '/' + this.state.hal)
        console.log(json)
        this.setState({
          news: bnews.concat(json),
          flagBerita: true,
          flagData: true,
          mode: mode,
          hal: parseInt(this.state.hal) + 1,
          isLoading: false
        })
      } else {
        //const json = await executeGet(TITIK_BACA_GET_RSS + '/' + mode + '/' + window.localStorage.getItem('alias') + '/' + this.state.lat + '/' + this.state.long + '/' + alt + '/1')
        let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSRandom/1/" + mode + "/" + window.localStorage.getItem('alias') + '/' + this.state.hal)
        console.log("MODEEEEEEEEE: " + mode)
        console.log(json)
        this.setState({
          news: json,
          mode: mode,
          flagBerita: true,
          flagData: true,
          hal: 1,
          isLoading: false
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    //console.log(this.state.news);
  }

  getBukuPopuler = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt);
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      const json = await executePost(TITIK_BACA + this.state.alias, formData)
      if (json.code == 404) {
        window.location.href = 'https://titikbaca.id'
      } else {
        this.setState({
          lokasi: json.nama_lokasi,
          data: json.data,
          alias: json.token,
          flagData: true,
          isLoading: false
        })
        this.getRSS(2)
      }
    } catch (error) {
      this.setState({ isError: true, isLoading: false, errorMessage: 'Network Error!' })
    }
  }

  gotoNewsDetail = async (tipe, token, idrss) => {
    if (tipe == 'news') {
      window.location.href = '/detailBerita/' + token + '/' + this.state.alias
    } else {
      window.location.href = '/rss/' + idrss
    }
  }

  gotoDetail = async (id) => {
    window.location.href = '/detailBuku/' + id + '/' + this.state.alias
  }

  gotoSearch = async (tipe) => {
    window.location.href = '/listBuku/' + tipe + '/' + this.state.alias
  }

  render() {
    return (
      <div>
        {this.state.isLoading && <SkeletonNews />}
        {this.state.flagData && (
          <>
            <nav className={"p-4 fixed flex w-full items-center top-0 z-10 " + this.state.color + " shadow-md"}>
              <div className="text-lg text-white font-semibold items-center">Berita</div>
            </nav>
            <div className="px-4 pt-16">
              {/* <!-- Titel Section --> */}
              <div className="flex justify-between items-center mt-2 mb-2">
                <h3 className="text-base text-black font-semibold">Berita Unggulan</h3>
              </div>

              {this.state.news.length > 0 && this.state.flagBerita && (
                <div className="relative overflow-hidden">
                  {this.state.news.slice(0, 1).map((item) => {
                    return (
                      <div onClick={() => this.gotoNewsDetail(item.tipe, item.link, item.id)}>
                        <img className="cursor-pointer w-full rounded-2xl" src={item.img + '?w=700&q=90'} alt="cover news" />
                        <div className="w-full max-h-32 p-4 absolute bottom-0 left-0 bg-gradient-to-t from-orange rounded-2xl">
                          <h3 className="mb-2 text-white font-semibold line-clamp-2">{item.title}</h3>
                          <div className="flex">
                            <div className="w-8 h-8 overflow-hidden rounded-full border-[1px] border-slate-300">
                              <img src={item.logo} alt="logo kumparan" />
                            </div>
                            <div className="ml-2 text-white text-xs">
                              <div>{item.publisher}</div>
                              <div>{formatTanggal(item.date)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              {this.state.noGPS === false && (
                <>
                  <ul className="flex my-4" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                    <li role="presentation">
                      <button onClick={() => this.switchRss(2)} className={'mr-2 inline py-2 px-6 text-sm font-semibold rounded-full ' + this.state.bgsemua + ' ' + this.state.txtsemua} id="local-tab" data-tabs-target="#local" type="button" role="tab" aria-controls="semua" aria-selected="true">
                        Semua
                      </button>
                    </li>
                    <li role="presentation">
                      <button onClick={() => this.switchRss(1)} className={'mr-2 inline py-2 px-6 text-orange text-sm font-semibold rounded-full ' + this.state.bglokal + ' ' + this.state.txtlokal} id="local-tab" data-tabs-target="#local" type="button" role="tab" aria-controls="sekitar" aria-selected="true">
                        Sekitar
                      </button>
                    </li>
                    <li role="presentation">
                      <button onClick={() => this.switchRss(0)} className={'mr-2 inline py-2 px-6 text-orange text-sm font-semibold rounded-full ' + this.state.bgnasional + ' ' + this.state.txtnasional} id="local-tab" data-tabs-target="#local" type="button" role="tab" aria-controls="nasional" aria-selected="true">
                        Nasional
                      </button>
                    </li>
                  </ul>
                </>
              )}
              <div id="myTabContent">
                <div id="local" role="tabpanel" aria-labelledby="local-tab">
                  {this.state.news.length > 0 && this.state.flagBerita && (
                    <>
                      {this.state.news.slice(1, 500).map((item) => {
                        return (
                          <>
                            <div onClick={() => this.gotoNewsDetail(item.tipe, item.link, item.id)} className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                              <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                              <div className="w-8/12">
                                <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                <div className="mt-1 flex items-center">
                                  <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                    <img src={item.logo} alt="logo kumparan" />
                                  </div>
                                  <div className="ml-2 text-white text-xs">
                                    <div className="text-xs text-gray-light">{item.publisher}</div>
                                    <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </>
                  )}
                </div>
                <div className="hidden" id="sekitar" role="tabpanel" aria-labelledby="global-tab">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    This is some placeholder content the <strong className="font-medium text-gray-800 dark:text-white">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classNamees to control the content visibility and styling.
                  </p>
                </div>
                <div className="hidden" id="nasional" role="tabpanel" aria-labelledby="global-tab">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    This is some placeholder content the <strong className="font-medium text-gray-800 dark:text-white">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classNamees to control the content visibility and styling.
                  </p>
                </div>
                <div className="text-center">
                  <button type="button" onClick={() => this.getRSS(this.state.mode)} className="mt-2 py-2 px-8 border-[1px] border-orange text-orange text-sm font-semibold text-center rounded-full">
                    Lihat Lebih Banyak
                  </button>
                </div>
              </div>
            </div>
            {this.state.flagData && this.state.data.length > 0 && (
              <div className="flex justify-between px-4 items-center mt-4 mb-2">
                <h3 className="text-base text-black font-semibold">Buku Populer</h3>
                <div onClick={() => this.gotoSearch(1)} className="text-xs font-medium text-orange">
                  Lihat Semua
                </div>
              </div>
            )}
            {/* <!-- End Title Section --> */}
            <div className="pl-4 mb-12 sm:mb-0 sm:block md:hidden">
              {this.state.flagData && (
                <Glider slidesToShow="3.3" slidesToScroll="1" draggable>
                  {/* <!-- Item Book --> */}
                  {this.state.data.length > 0 && this.state.flagData && (
                    <>
                      {this.state.data.slice(0, 6).map((item) => {
                        return (
                          <>
                            <div className="p-1">
                              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                                <div className="h-[130px] overflow-hidden" onClick={() => this.gotoDetail(item.alias)}>
                                  <img src={item.cover_file} alt="cover buku" className="w-full rounded-xl" />
                                </div>
                                <div className="p-2 min-h-[120px]">
                                  <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                  <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                  <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                  <div className="stock mt-1 text-sm text-orange font-semibold">
                                    Stok: {item.terpakai}/{item.qty}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </>
                  )}
                </Glider>
              )}
            </div>
            <div className="pl-4 mb-20 hidden md:block">
              {this.state.flagData && (
                <Glider slidesToShow="5.3" slidesToScroll="1" draggable>
                  {/* <!-- Item Book --> */}
                  {this.state.data.length > 0 && this.state.flagData && (
                    <>
                      {this.state.data.slice(0, 6).map((item) => {
                        return (
                          <>
                            <div className="p-1">
                              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                                <div className="h-[180px] overflow-hidden" onClick={() => this.gotoDetail(item.alias)}>
                                  <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                                </div>
                                <div className="p-2 min-h-[120px]">
                                  <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                  <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                  <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                  <div className="stock mt-1 text-sm text-orange font-semibold">
                                    Stok: {item.terpakai}/{item.qty}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </>
                  )}
                </Glider>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default News
