import React, { Component } from 'react'
import { executePost, createNewHeaders, goBack } from '../functions/Kubuku'
import { BASE_URL, TITIK_BACA } from '../api'
import SkeletonGPSLocation from '../components/SkeletonGPSLocation'
import enable_location from '../assets/images/enable-location.png'

class GPSLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      alias: 'kfh-12f9-kas',
      lokasi: '',
      nama: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      dataterdekat: '',
      flagData: false,
      noGPS: false,
      msgTitleGPS: 'Aktifkan Servis Lokasi',
      msgGPS: 'Tidak dapat mendeteksi lokasi anda, pastikan GPS anda aktif, dan mengijinkan browser anda membaca lokasi anda'
    }
  }

  componentDidMount = async () => {
    this.getLocation()
    this.setState({
      nama: window.localStorage.getItem('nama'),
      alias: window.localStorage.getItem('alias')
    })
  }

  getLocation = () => {
    if (navigator.geolocation) {
      var location_timeout = setTimeout('this.geolocFail()', 30000)

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(location_timeout)
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let alt = position.coords.altitude;
          this.getTitikBacaTerdekat(lat, long, alt)
        },
        (error) => {
          clearTimeout(location_timeout)
          this.geolocFail()
        }
      )
    } else {
      this.geolocFail()
    }
  }

  geolocFail = () => {
    this.setState({
      noGPS: true,
      isLoading: false
    })
    //this.getRSS(1);
  }

  gotoLocation = async (alias) => {
    window.location.href = '/' + alias
  }

  getTitikBacaTerdekat = async (lat, long, alt) => {
    try {
      createNewHeaders()
      this.setState({
        flagData: false
      })
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      const json = await executePost(BASE_URL + 'titikPerpusnas/', formData)
      if (json.code == 404) {
        window.location.href = 'https://titikbaca.id'
      } else {
        this.setState({
          dataterdekat: json,
          flagData: true,
          isLoading: false
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading && <SkeletonGPSLocation />}
        {this.state.noGPS && (
          <>
            <nav className="fixed top-0 w-full z-10 shadow-md">
              <div onClick={() => goBack()} className="flex p-4 bg-orange text-lg text-white font-semibold items-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-8">
                  <path d="M15 6L9 12L15 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="line-clamp-1 ">Lokasi Tidak Aktif</span>
              </div>
            </nav>
            <div className="relative mt-[105px] mb-2">
              <div className="px-4">
                <img src={enable_location} alt="aktifkan lokasi" className="w-44 mx-auto" />
                <div className="px-4 mt-4 mb-6 text-center">
                  <h3 className="text-black text-lg font-semibold">{this.state.msgTitleGPS}</h3>
                  <p className="mt-2 text-sm text-gray-light">{this.state.msgGPS}</p>
                  <button type="button" className="block mx-auto mt-4 py-2 px-10 bg-orange text-white font-semibold rounded-full">
                    Aktifkan
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.flagData && (
          <>
            <div className="fixed top-0 mapouter w-full">
              <div className="gmap_canvas w-full">
                <iframe title="gmaps canvas" className="w-full" height="500" id="gmap_canvas" src={'https://maps.google.com/maps?q=' + this.state.lat + '%2C' + this.state.long + '&output=embed'} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                <a href="https://putlocker-is.org"></a>
                <br />
                <style></style>
                <a href="https://www.embedgooglemap.net">how to add google maps to a website</a>
                <style></style>
              </div>
            </div>
            {/* Main Content Wrap */}
            <div className="relative mt-[530px]  sm:mt-[25%] md:mt-[80%] mb-[-80px] bg-white z-1 before:absolute before:top-[-25px] before:left-0 before:w-full before:h-[25px] before:bg-white before:rounded-t-2xl after:absolute after:w-20 after:h-1 after:top-[-14px] after:left-[38%] after:sm:left-[44%] after:bg-gray-200 after:rounded-full">
              {/* Your Location */}
              <div className="flex mt-3 px-4">
                <div className="h-[45px] w-[45px] mr-4 bg-orange/10 rounded-full text-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mx-auto">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.7808 19.7005C17.0905 16.7551 20 12.4759 20 9.6087C20 4.85424 16.4183 1 12 1C7.58172 1 4 4.85424 4 9.6087C4 12.4759 6.90945 16.7551 9.21921 19.7005C10.4242 21.2371 11.0268 22.0055 12 22.0055C12.9732 22.0055 13.5758 21.2371 14.7808 19.7005ZM12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13Z" fill="#EB5833" />
                  </svg>
                </div>
                <div>
                  <div className="text-xs text-gray-light">Lokasi Anda Saat Ini</div>
                  <h3 className="font-medium text-sm text-black mt-1">{this.state.nama}</h3>
                </div>
              </div>
              {/* End Your Location */}

              {/* Titel Section */}
              <div className="flex justify-between px-4 items-center mt-4 mb-3">
                <h3 className="text-base font-semibold text-black">TitikBaca Terdekat</h3>
              </div>
              {/* End Title Section */}
              {/* Location List Item */}
              
              {this.state.dataterdekat.length > 1 && this.state.flagData && (
                <div className="md:mt-3">
                  {this.state.dataterdekat.slice(0, 15).map((item) => {
                    let mySentence = item.nama.toLowerCase()
                    let words = mySentence.split(' ')

                    for (let i = 0; i < words.length; i++) {
                      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
                    }

                    let titik = words.join(' ')
                    if (item.alias != this.state.alias && item.alias != 'kfh-12f9-kas') {
                      return (
                        <>
                          <div className="cursor-pointer flex mb-4 px-4 items-center" onClick={() => this.gotoLocation(item.alias)}>
                            <div className="h-[45px] w-[45px] mr-4 bg-orange/10 rounded-full text-center">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mx-auto">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.7808 19.7005C17.0905 16.7551 20 12.4759 20 9.6087C20 4.85424 16.4183 1 12 1C7.58172 1 4 4.85424 4 9.6087C4 12.4759 6.90945 16.7551 9.21921 19.7005C10.4242 21.2371 11.0268 22.0055 12 22.0055C12.9732 22.0055 13.5758 21.2371 14.7808 19.7005ZM12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13Z" fill="#EB5833" />
                              </svg>
                            </div>
                            <div>
                              <h3 className="font-medium text-sm text-black line-clamp-1">{titik}</h3>
                            </div>
                          </div>
                        </>
                      )
                    }
                  })}
                </div>
              )}
            </div>
            {/* End Main Content Wrap */}
          </>
        )}
      </div>
    )
  }
}

export default GPSLocation
