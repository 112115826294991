import { Component } from 'react'
import logo_titikbaca from '../assets/images/logo-titikbaca.png'
import {TITIK_BACA_CONTENT_CATEGORIES, TITIK_BACA, TITIK_BACA_GET_THEMES, TITIK_BACA_REKOMENDASI, TITIK_BACA_TERLARIS, TITIK_BACA_TOP, TITIK_BACA_BERITA, TITIK_BACA_GET_RSS, TITIK_BACA_YOUTUBE, GET_RADIO, TITIK_BACA_KATEGORI, BASE_URL } from '../api'
import { shuffle, executeGet, executePost, createNewHeaders, formatTanggal } from '../functions/Kubuku'
import Glider from 'react-glider'
import 'glider-js/glider.min.css'
import enable_location from '../assets/images/enable-location.png'
import image_location from '../assets/images/location.png'
import logoHero from '../assets/images/hero.png'

class ContentNoGPS extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isModal: false, //popup loading
      showMain: false,
      param: this.props.params,
      dataterdekat: '',
      dataterdekat_array: [],
      data: '',
      news: [],
      mode: 1,
      hal: 1,
      dataRekomendasi: '',
      dataTerlaris: '',
      dataTop: [],
      dataBerita: '',
      datakategori: '',
      youtube: [],
      dataRadio: [],
      flagRadio: false,
      flagData: false,
      flagBerita: false,
      flagDataRSS: false,
      dataRSS: [],
      halRSS: 2,
      dataRssKominfo: [],
      flagRSSKominfo: false,
      flagRekomendasi: false,
      flagYoutube: false,
      flagDataTop: false,
      showModal: false, //popup preview
      alias: 'kfh-12f9-kas',
      lokasi: 'Selamat Datang',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      jauh: false,
      msgJauh: '',
      lokasiMsgJauh: '',
      halaman: 1,
      noGPS: false,
      flagMoreSearch: false,
      urlTitikBaca: "",
      hasMore: true,
      msgTitleGPS: 'Aktifkan Servis Lokasi',
      msgGPS: 'Tidak dapat mendeteksi lokasi anda, pastikan GPS anda aktif, dan mengijinkan browser anda membaca lokasi anda',
      color: 'bg-orange',
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash:'',
      flagAudio: false,
      dataAudio: [],
      flagVideo: false,
      dataVideo: []
    }
  }
  
    componentDidMount = async () => {
      this.getTheme();
      this.getLocation();
    }

    getLocation = () => {
      if (navigator.geolocation) {
        var location_timeout = setTimeout('this.geolocFail()', 30000)
  
        navigator.geolocation.getCurrentPosition(
          (position) => {
            clearTimeout(location_timeout);
            console.log("YES");
          },
          (error) => {
            this.setState({
                noGPS: true,
                isLoading:false
            })
            this.getRSS(1, 1)
            this.getYoutube(1, 1, 1)
            this.getRadio()
            this.getRSSKominfo();
          }
        )
      } else {
        console.log("NO");
        this.setState({
            noGPS: true,
            isLoading:false
        })
        this.getRSS(1, 1)
        this.getYoutube(1, 1, 1)
        this.getRadio()
        this.getRSSKominfo();
      }
    }
    geolocFail = async()=> {
        this.setState({
            noGPS: true,
            isLoading:false
        })
        this.getRSS(1, 1)
        this.getYoutube(1, 1, 1)
        this.getRadio()
        this.getRSSKominfo();
    }
    getRSS = async (mode, hal) => {
      try {
        let oNews = this.state.news
        let formData = new FormData()
        formData.append('latitude', '1')
        formData.append('longitude', '1')
        formData.append('altitude', '1')
        formData.append('alias', this.state.alias)
        formData.append('isMobile', '1')
        formData.append('hal', hal)
        //const json = await executePost(TITIK_BACA_GET_RSS + '/' + mode, formData)
        let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSRandom/0/0/" + window.localStorage.getItem('alias') + "/1")
        
        this.setState({
          news: oNews.concat(json),
          flagBerita: true,
          mode: mode,
          noGPS: true,
          hal: 1
          //isLoading: false
        });
      } catch (error) {
        this.setState({ isError: true, errorMessage: 'Network Error!' })
      }
    }
    
    getRSSKominfo = async () => {
      try {
        //const json = await executeGet(TITIK_BACA_GET_RSS + '/jakarta/1')
        
        let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSKumparan")
        this.setState({
          dataRssKominfo: json,
          flagRSSKominfo: true,
          hal: 1
          //isLoading: false
        });
      } catch (error) {
        this.setState({ isError: true, errorMessage: 'Network Error!' })
      }
    }
  
  
    getYoutube = async (lat, long, alt) => {
      try {
        let formData = new FormData()
        formData.append('latitude', lat)
        formData.append('longitude', long)
        formData.append('altitude', alt)
        formData.append('isMobile', '1')
        formData.append('hal', 1)
        const json = await executePost(TITIK_BACA_YOUTUBE + this.state.alias, formData)
        this.setState({
          youtube: json.data,
          flagYoutube: true,
          hal: 1,
          isLoading: false
        })
      } catch (error) {
        this.setState({ isError: true, errorMessage: 'Network Error!' })
      }
    }

    getRadio = async () => {
      try {
        this.setState({
          isLoading: true
        })
        const json = await executeGet(GET_RADIO)
        if (json.code == 404) {
          window.location.href = 'https://titikbaca.id'
        } else {
          let newArray = [...json]
          let length = newArray.length
  
          for (let start = 0; start < length; start++) {
            const randomPosition = Math.floor((newArray.length - start) * Math.random())
            const randomItem = newArray.splice(randomPosition, 1)
            newArray.push(...randomItem)
          }
          this.setState({
            dataRadio: newArray,
            isLoading: false,
            flagRadio: true
          })
        }
      } catch (error) {
        //console.log(error);
        this.setState({ isError: true, errorMessage: 'Network Error!' })
      }
    }

    getTheme = async () => {
      try {
        //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
        if (window.localStorage.getItem('themes') != null) {
          const ljson = window.localStorage.getItem('themes');
          const json = JSON.parse(ljson)
          if (json.code == 200) {
            if (json.warna!="") {
              this.setState({
                color: json.warna
              });
            }
            if (json.dark!="") {
              this.setState({
                darkcolor: json.dark
              });
            }
            if (json.text!="") {
              this.setState({
                textcolor: json.text
              });
            }
            if (json.head!="") {
              this.setState({
                imgHead: json.head
              });
            }
            if (json.splash!="") {
              this.setState({
                imgSplash: json.splash
              });
            }
          }
        }
      } catch (error) {
        this.setState({ isError: true, errorMessage: 'Network Error!' })
      }
    }

    
    render() {
      return (
        <>
        {/* Section Hero */}
            <div className={"fixed w-full top-0 left-0 p-4 pb-12 " + this.state.color + " text-white"}>
              <img src={logo_titikbaca} alt="logo titikbaca" className="mb-3"  style={{height:"41px"}} />
              <div className="flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.7808 19.7005C17.0905 16.7551 20 12.4759 20 9.6087C20 4.85424 16.4183 1 12 1C7.58172 1 4 4.85424 4 9.6087C4 12.4759 6.90945 16.7551 9.21921 19.7005C10.4242 21.2371 11.0268 22.0055 12 22.0055C12.9732 22.0055 13.5758 21.2371 14.7808 19.7005ZM12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13Z" fill="#FAFAFA" />
                </svg>
                <h3 className="text-base font-medium line-clamp-1">GPS tidak Aktif</h3>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className={"mt-4 p-4 " + this.state.darkcolor + " bg-opacity-20 rounded-2xl"}>
                  <p className="text-sm">Cari buku yang ingin kamu baca</p>
                  <button className={"flex w-full mt-2 p-1 items-center text-xs border border-white rounded-full hover:bg-white hover:" + this.state.textcolor + " duration-150 ease-in-out"}>
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 ml-3">
                      <path
                        d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L19.5304 17.4698ZM22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L22.4696 22.5304ZM9.33512 4.80232C9.74423 4.73752 10.0234 4.35334 9.95856 3.94423C9.89376 3.53511 9.50958 3.25599 9.10047 3.32079L9.33512 4.80232ZM4.32076 8.1005C4.25596 8.50961 4.53508 8.89379 4.9442 8.95859C5.35331 9.02339 5.73749 8.74426 5.80229 8.33515L4.32076 8.1005ZM18.4697 18.5304L22.4696 22.5304L23.5303 21.4697L19.5304 17.4698L18.4697 18.5304ZM11 18.25C6.44365 18.25 2.75 14.5563 2.75 10H1.25C1.25 15.3848 5.61522 19.75 11 19.75V18.25ZM19.25 10C19.25 14.5563 15.5563 18.25 11 18.25V19.75C16.3848 19.75 20.75 15.3848 20.75 10H19.25ZM11 1.75C15.5563 1.75 19.25 5.44365 19.25 10H20.75C20.75 4.61522 16.3848 0.25 11 0.25V1.75ZM11 0.25C5.61522 0.25 1.25 4.61522 1.25 10H2.75C2.75 5.44365 6.44365 1.75 11 1.75V0.25ZM9.10047 3.32079C6.64008 3.71047 4.71044 5.64012 4.32076 8.1005L5.80229 8.33515C6.09032 6.51661 7.51658 5.09035 9.33512 4.80232L9.10047 3.32079Z"
                        fill="white"
                      />
                    </svg>
                    Cari Buku
                  </button>
                </div>
                <div className='ml-8'>
                  <img src={logoHero} alt="hero" className="h-[120px]" />
                </div>
              </div>
            </div>
            
            <div className="relative mt-[250px] mb-[70px] sm:mb-0 sm:mt-[280px] md:mb-[70px] bg-white z-10 before:absolute before:top-[-25px] before:left-0 before:w-full before:h-[25px] before:bg-white before:rounded-t-2xl after:absolute after:w-20 after:h-1 after:top-[-14px] after:left-[38%] after:sm:left-[44%] after:bg-gray-200 after:rounded-full">
              <div className="px-4">
                {this.state.flagYoutube && this.state.youtube.length > 0 && (
                  <>
                    {/* <!-- Titel Section --> */}
                    <div className="flex justify-between px-4 items-center mt-4 mb-2">
                      <div className="flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6.98901 4.89052C10.3247 4.62955 13.6756 4.62955 17.0113 4.89052L19.252 5.06582C20.5001 5.16346 21.5211 6.09886 21.7274 7.33363C22.2436 10.4231 22.2436 13.5769 21.7274 16.6664C21.5211 17.9011 20.5001 18.8365 19.252 18.9342L17.0113 19.1095C13.6756 19.3704 10.3247 19.3704 6.989 19.1095L4.7483 18.9342C3.50023 18.8365 2.47921 17.9011 2.2729 16.6664C1.75669 13.5769 1.75669 10.4231 2.2729 7.33363C2.47921 6.09886 3.50023 5.16346 4.7483 5.06582L6.98901 4.89052ZM10.0001 14.4701V9.52986C10.0001 9.29667 10.2545 9.15263 10.4545 9.27261L14.5714 11.7428C14.7656 11.8593 14.7656 12.1407 14.5714 12.2572L10.4545 14.7274C10.2545 14.8474 10.0001 14.7033 10.0001 14.4701Z" fill="#E23D31" />
                        </svg>
                        <h3 className="ml-1 text-base text-black font-semibold">Video Untukmu</h3>
                      </div>
                      <a href="/youtube/0" className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                        Lihat Semua
                      </a>
                    </div>
                    {/* <!-- End Title Section --> */}
                    <div className="pl-4 md:hidden">
                      <Glider draggable slidesToShow={1.5} slidesToScroll={1}>
                        {this.state.youtube.map((item) => {
                          return (
                            <div className="pr-2 p-1">
                              <div onClick={() => this.gotoYoutube(item.id)} className="mb-1 border bg-white rounded-2xl shadow-md hover:shadow-lg">
                                <div className="max-h-[155px] m-1 overflow-hidden">
                                  <img className="rounded-lg" src={item.thumbnail} alt="cover news" />
                                </div>
                                <div className="flex min-h-[54px] p-2.5 items-center">
                                  
                                  <div className="w-12/12">
                                    <h3 className="mb-1 font-semibold text-sm text-black line-clamp-1">{item.judul}</h3>
                                    <div className="leading-[10px] overflow-hidden">
                                      <div className="inline text-xs text-gray-light mr-0.5">{item.channel}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Glider>
                    </div>
                    <div className="pl-4 hidden md:block">
                      <Glider draggable slidesToShow={3.5} slidesToScroll={1}>
                        {this.state.youtube.map((item) => {
                          return (
                            <div className="p-2">
                              <div onClick={() => this.gotoYoutube(item.id)} className="mb-2.5 border bg-white rounded-2xl shadow-md hover:shadow-lg">
                                <div className="max-h-[165px] overflow-hidden">
                                  <img className="rounded-lg" src={item.thumbnail} alt="cover news" />
                                </div>
                                <div className="flex min-h-[96px] p-2.5 items-center">
                                  
                                  <div className="w-12/12">
                                    <h3 className="mb-1 font-semibold text-sm text-black line-clamp-2">{item.judul}</h3>
                                    <div className="leading-[10px] overflow-hidden">
                                      <div className="inline text-xs text-gray-light mr-0.5">{item.channel}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Glider>
                    </div>
                  </>
                )}
                <img src={enable_location} alt="aktifkan lokasi" className="w-44 mx-auto" />
                <div className="px-4 mt-4 mb-6 text-center">
                  <h3 className="text-black text-lg font-semibold">{this.state.msgTitleGPS}</h3>
                  <p className="mt-2 text-sm text-gray-light">{this.state.msgGPS}</p>
                  <button onClick={() => this.aktifkanGeo()} type="button" className={"block mx-auto mt-4 py-2 px-10 " + this.state.color + " text-white font-semibold rounded-full"}>
                    Aktifkan
                  </button>
                </div>
                {/* <!-- Titel Section --> */}
                <div className="flex justify-between items-center mt-2 mb-2">
                  <h3 className="text-base text-black font-semibold">Berita Unggulan</h3>
                </div>
                {/* <!-- End Title Section --> */}
                <div id="myTabContent">
                <div className="hidden md:block">
                    
                    {/* <!-- End Title Section --> */}
                    <div className="flex">
                      <div className="w-7/12 relative overflow-hidden mb-2 mr-2">
                        {this.state.news.slice(0, 1).map((item) => {
                          return (
                            <div onClick={() => this.gotoRSSDetail(item.tipe, item.id)}>
                              <img className="cursor-pointer w-full rounded-2xl" src={item.img + '?w=700&q=90'} alt="cover news" />
                              <div className="w-full max-h-32 p-4 absolute bottom-0 left-0 bg-gradient-to-t from-orange rounded-2xl">
                                <h3 className="mb-2 text-white font-semibold line-clamp-2">{item.title}</h3>
                                <div className="flex">
                                  <div className="w-8 h-8 overflow-hidden rounded-full border-[1px] border-slate-300">
                                    <img src={item.logo} alt="logo kumparan" />
                                  </div>
                                  <div className="ml-2 text-white text-xs">
                                    <div>{item.publisher}</div>
                                    <div>{formatTanggal(item.date)}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="w-5/12 ml-2">
                        {this.state.news.slice(1, 4).map((item) => {
                          return (
                            <>
                              <div onClick={() => this.gotoRSSDetail(item.tipe, item.id)} className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                <div className="w-8/12">
                                  <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                  <div className="mt-1 flex items-center">
                                    <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                      <img src={item.logo} alt="logo kumparan" />
                                    </div>
                                    <div className="ml-2 text-white text-xs">
                                      <div className="text-xs text-gray-light">{item.publisher}</div>
                                      <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                    <div id="myTabContent">
                      <div id="local" role="tabpanel" aria-labelledby="local-tab">
                        {this.state.news.slice(4, 10).map((item) => {
                          return (
                            <>
                              <div onClick={() => this.gotoRSSDetail(item.tipe, item.id)} className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                <div className="w-8/12">
                                  <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                  <div className="mt-1 flex items-center">
                                    <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                      <img src={item.logo} alt="logo kumparan" />
                                    </div>
                                    <div className="ml-2 text-white text-xs">
                                      <div className="text-xs text-gray-light">{item.publisher}</div>
                                      <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='md:hidden' id="local" role="tabpanel" aria-labelledby="local-tab">
                    {this.state.news.length > 0 && this.state.flagBerita && (
                      <div className="relative overflow-hidden">
                        {this.state.news.slice(0, 1).map((item) => {
                          return (
                            <div onClick={() => this.gotoNewsDetail(item.tipe, item.link)}>
                              <img className="cursor-pointer w-full rounded-2xl" src={item.img + '?w=700&q=90'} alt="cover news" />
                              <div className="w-full max-h-32 p-4 absolute bottom-0 left-0 bg-gradient-to-t from-orange rounded-2xl">
                                <h3 className="mb-2 text-white font-semibold line-clamp-2">{item.title}</h3>
                                <div className="flex">
                                  <div className="w-8 h-8 overflow-hidden rounded-full border-[1px] border-slate-300">
                                    <img src={item.logo} alt="logo kumparan" />
                                  </div>
                                  <div className="ml-2 text-white text-xs">
                                    <div>{item.publisher}</div>
                                    <div>{formatTanggal(item.date)}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                    
                    {this.state.news.length > 1 && this.state.flagBerita && (
                      <>
                        {this.state.news.slice(1,6).map((item) => {
                          return (
                            <>
                              <div onClick={() => this.gotoRSSDetail(item.tipe, item.id)} className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                <div className="w-8/12">
                                  <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                  <div className="mt-1 flex items-center">
                                    <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                      <img src={item.logo} alt="logo kumparan" />
                                    </div>
                                    <div className="ml-2 text-white text-xs">
                                      <div className="text-xs text-gray-light">{item.publisher}</div>
                                      <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {this.state.flagRadio && this.state.dataRadio.length > 0 && (
                <>
                  {/* <!-- Titel Section --> */}
                  <div className="mt-2 mb-2 px-4">
                    <h3 className="text-base text-black font-semibold">Siaran Radio</h3>
                  </div>
                  {/* <!-- End Title Section --> */}
                  <div className="hidden md:block mb-2 px-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                      {this.state.dataRadio.slice(0,4).map((item) => {
                        return (
                          <div onClick={() => this.gotoRadioDetail(item)} className="break-inside-avoid rounded-xl mb-2 shadow-md">
                            <div className="h-[165px] overflow-hidden">
                              <img src={item.imageRadio} alt="cover buku" className="rounded-xl w-full mx-auto" />
                            </div>
                            <div className="p-2">
                              <div className="title-book text-sm text-black font-semibold line-clamp-1">{item.nama_channel}</div>
                              <div className="author mt-1 text-xs text-gray-light line-clamp-2">{item.tagline}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="md:hidden mb-2 px-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                      {this.state.dataRadio.slice(0,2).map((item) => {
                        return (
                          <div onClick={() => this.gotoRadioDetail(item)} className="break-inside-avoid rounded-xl mb-2 shadow-md">
                            <div className="h-[165px] overflow-hidden">
                              <img src={item.imageRadio} alt="cover buku" className="rounded-xl w-full mx-auto" />
                            </div>
                            <div className="p-2">
                              <div className="title-book text-sm text-black font-semibold line-clamp-1">{item.nama_channel}</div>
                              <div className="author mt-1 text-xs text-gray-light line-clamp-2">{item.tagline}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
              )}
              {this.state.flagRSSKominfo && this.state.dataRssKominfo.length >0 &&
                <>
                  <div >
                    <div className="md:mt-3 p-2">
                          <div className="hidden md:block">
                          <div className="flex justify-between items-center m-2 mt-4 mb-2">
                            <div className="flex">
                              <h3 className="ml-1 text-base text-black font-semibold">Berita</h3>
                            </div>
                            <a href="/listrss/0" className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                              Lihat Semua
                            </a>
                          </div>
                            <div className='grid grid-cols-2' id="local" role="tabpanel" aria-labelledby="local-tab">
                              {this.state.dataRssKominfo.map((item) => {
                                return (
                                  <>
                                    <div onClick={() => this.gotoRSSDetail(item.link, item.id)} className="flex mb-2.5 p-2.5 m-1 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                    
                                    <div className="w-9/12">
                                      <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                      <div className="mt-1 flex items-center">
                                        <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                          <img src={item.logo} alt="logo kumparan" />
                                        </div>
                                        <div className="ml-2 text-white text-xs">
                                          <div className="text-xs text-gray-light">{item.publisher}</div>
                                          <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] ml-3 mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                    </div>
                                  </>
                                )
                              }
                              )}
                            </div>
                            
                          </div>
                          
                          <div className="px-2 md:hidden">
                          <div className="flex justify-between items-center mt-4 mb-2">
                            <div className="flex">
                              <h3 className="ml-1 text-base text-black font-semibold">Berita</h3>
                            </div>
                            <a href="/listrss/0" className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                              Lihat Semua
                            </a>
                          </div>
                            {this.state.dataRssKominfo.slice(0,4).map((item, index) => {
                              
                              return (
                                <>
                                  <div onClick={() => this.gotoRSSDetail(item.link, item.id)} className="flex mb-2.5 p-2 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                    
                                    <div className="w-9/12">
                                      <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                      <div className="mt-1 flex items-center">
                                        <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                          <img src={item.logo} alt="logo kumparan" />
                                        </div>
                                        <div className="ml-2 text-white text-xs">
                                          <div className="text-xs text-gray-light">{item.publisher}</div>
                                          <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                    </div>
                                </>
                              )
                            
                            }
                            )}
                          </div>
                    </div>
                  </div>
                </>
              }
              {this.state.flagRadio && this.state.dataRadio.length > 0 && (
                <>
                  {/* <!-- Titel Section --> */}
                  <div className="mt-2 mb-2 px-4">
                    <h3 className="text-base text-black font-semibold">Siaran Radio</h3>
                  </div>
                  {/* <!-- End Title Section --> */}
                  <div className="mb-20 px-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                      {this.state.dataRadio.slice(3).map((item) => {
                        return (
                          <div onClick={() => this.gotoRadioDetail(item)} className="break-inside-avoid rounded-xl mb-2 shadow-md">
                            <div className="h-[165px] overflow-hidden">
                              <img src={item.imageRadio} alt="cover buku" className="rounded-xl w-full mx-auto" />
                            </div>
                            <div className="p-2">
                              <div className="title-book text-sm text-black font-semibold line-clamp-1">{item.nama_channel}</div>
                              <div className="author mt-1 text-xs text-gray-light line-clamp-2">{item.tagline}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
        </>
      );
      }
}

export default ContentNoGPS